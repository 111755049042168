import { BehaviorSubject } from 'rxjs';
import * as i0 from '@angular/core';
import { Injectable, Pipe, Component, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
function NgxValidatorComponent_ng_container_0_p_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 2);
    i0.ɵɵelement(1, "span", 3);
    i0.ɵɵpipe(2, "getErrorMessage");
    i0.ɵɵpipe(3, "async");
    i0.ɵɵpipe(4, "getInterpolationData");
    i0.ɵɵpipe(5, "interpolation");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("innerHTML", i0.ɵɵpipeBind2(5, 10, i0.ɵɵpipeBind1(3, 4, i0.ɵɵpipeBind2(2, 1, ctx_r0.control == null ? null : ctx_r0.control.errors, ctx_r0.customValidation)), i0.ɵɵpipeBind3(4, 6, ctx_r0.control == null ? null : ctx_r0.control.errors, ctx_r0.customName, ctx_r0.control)), i0.ɵɵsanitizeHtml);
  }
}
function NgxValidatorComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, NgxValidatorComponent_ng_container_0_p_1_Template, 6, 13, "p", 1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.control == null ? null : ctx_r0.control.errors);
  }
}
const VALIDATION_MESSAGES = {
  required: '{{field}} required',
  email: 'Email is not valid',
  minlength: '{{field}} should have minimum {{length}} characters',
  maxlength: '{{field}} should have maximum {{length}} characters',
  min: '{{field}} minimum value is {{min}}',
  max: '{{field}} maximum value is {{max}}',
  pattern: '{{field}} have incorrect format',
  passwordNotEquals: 'Passwords are not the same',
  atLeastOne: 'At least one is required'
};
class NgxValidatorService {
  constructor() {
    this.messages = new BehaviorSubject({
      messages: VALIDATION_MESSAGES
    });
    this.messages$ = this.messages.asObservable();
    this.backendValidation = new BehaviorSubject({});
    this.backendValidation$ = this.backendValidation.asObservable();
  }
  setValidationMessages(messages) {
    const currentMessages = this.messages.value.messages;
    this.messages.next({
      messages: {
        ...currentMessages,
        ...messages
      }
    });
  }
  setBackendErrorsOnForm(form, backendErrors) {
    const generatedErrors = {};
    Object.keys(backendErrors).forEach(key => {
      const errorKey = `BE_${key}`;
      if (form.controls[key]) {
        if (Array.isArray(backendErrors[key])) {
          form.controls[key].setErrors({
            [errorKey]: true
          });
          generatedErrors[errorKey] = backendErrors[key].join(' | ');
        }
      }
    });
    this.setValidationMessages(generatedErrors);
  }
}
NgxValidatorService.ɵfac = function NgxValidatorService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxValidatorService)();
};
NgxValidatorService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgxValidatorService,
  factory: NgxValidatorService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxValidatorService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class InterpolationPipe {
  transform(text = '', messages) {
    if (!text) {
      return '';
    }
    const bracketValues = this.giveWords(text);
    if (!bracketValues) {
      return text;
    }
    bracketValues.forEach(item => {
      const clearWord = this.giveWord(item);
      if (!messages[clearWord]) {
        messages[clearWord] = '';
      }
      text = text?.replace(item, this.getPropertyValue(messages, clearWord));
    });
    return `${text}`;
  }
  giveWords(text) {
    const regExp = /\{\{(.+?)\}\}/ig;
    const words = text.match(regExp);
    return words ?? [];
  }
  giveWord(text) {
    const regExp = /\{{(.*)\}}/i;
    const words = text.match(regExp);
    return words ? words[1] : '';
  }
  getPropertyValue(item, property) {
    if (property.includes('.')) {
      return property.split('.').reduce((object, key) => object ? object[key] : '-', item);
    }
    return item[property];
  }
}
InterpolationPipe.ɵfac = function InterpolationPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || InterpolationPipe)();
};
InterpolationPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "interpolation",
  type: InterpolationPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InterpolationPipe, [{
    type: Pipe,
    args: [{
      name: 'interpolation'
    }]
  }], null, null);
})();
class GetErrorMessagePipe {
  constructor(ngxValidatorService) {
    this.ngxValidatorService = ngxValidatorService;
  }
  transform(errors, customValidation) {
    return this.ngxValidatorService.messages$.pipe(map(response => {
      const properties = Object.keys(errors || {});
      let customMessage = null;
      let messages = response.messages;
      if (customValidation && Array.isArray(customValidation)) {
        customMessage = customValidation.find(validation => properties.includes(validation.name));
      }
      if (customValidation && !Array.isArray(customValidation)) {
        const isCustomMessage = properties.some(property => customValidation.name === property);
        if (isCustomMessage) {
          customMessage = customValidation;
        }
      }
      if (customMessage) {
        messages = {
          ...messages,
          [customMessage.name]: customMessage.text
        };
      }
      return messages[properties[0]];
    }));
  }
}
GetErrorMessagePipe.ɵfac = function GetErrorMessagePipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GetErrorMessagePipe)(i0.ɵɵdirectiveInject(NgxValidatorService, 16));
};
GetErrorMessagePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "getErrorMessage",
  type: GetErrorMessagePipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GetErrorMessagePipe, [{
    type: Pipe,
    args: [{
      name: 'getErrorMessage'
    }]
  }], function () {
    return [{
      type: NgxValidatorService
    }];
  }, null);
})();
class GetInterpolationDataPipe {
  transform(errors, customName, control) {
    if (!errors) {
      return {};
    }
    const properties = Object.keys(errors);
    const name = customName ? customName : this.prettifyControlName(this.getControlName(control));
    switch (properties[0]) {
      case 'min':
        return {
          field: name,
          min: errors['min']['min']
        };
      case 'max':
        return {
          field: name,
          max: errors['max']['max']
        };
      case 'minlength':
        return {
          field: name,
          length: errors['minlength']['requiredLength']
        };
      case 'maxlength':
        return {
          field: name,
          length: errors['maxlength']['requiredLength']
        };
      default:
        return {
          field: name,
          value: errors[properties[0]]?.value
        };
    }
  }
  getControlName(control) {
    let controlName = '';
    const parent = control ? control.parent : '';
    if (parent instanceof FormGroup) {
      for (const name in parent.controls) {
        if (control === parent.controls[name]) {
          controlName = name;
        }
      }
    }
    return controlName;
  }
  prettifyControlName(name) {
    return name.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/[\s_]+/g, ' ').toLowerCase();
  }
}
GetInterpolationDataPipe.ɵfac = function GetInterpolationDataPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GetInterpolationDataPipe)();
};
GetInterpolationDataPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "getInterpolationData",
  type: GetInterpolationDataPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GetInterpolationDataPipe, [{
    type: Pipe,
    args: [{
      name: 'getInterpolationData'
    }]
  }], null, null);
})();
class NgxValidatorComponent {}
NgxValidatorComponent.ɵfac = function NgxValidatorComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxValidatorComponent)();
};
NgxValidatorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgxValidatorComponent,
  selectors: [["ngx-validator"]],
  inputs: {
    control: "control",
    customName: "customName",
    customValidation: "customValidation"
  },
  decls: 1,
  vars: 1,
  consts: [[4, "ngIf"], ["class", "ngx-validator", 4, "ngIf"], [1, "ngx-validator"], [3, "innerHTML"]],
  template: function NgxValidatorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, NgxValidatorComponent_ng_container_0_Template, 2, 1, "ng-container", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", (ctx.control == null ? null : ctx.control.invalid) && (ctx.control == null ? null : ctx.control.touched));
    }
  },
  dependencies: [i1.NgIf, InterpolationPipe, i1.AsyncPipe, GetErrorMessagePipe, GetInterpolationDataPipe],
  styles: ["p[_ngcontent-%COMP%]{margin:0;display:inline-block}p[_ngcontent-%COMP%]:first-letter{text-transform:capitalize}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxValidatorComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-validator',
      template: `
    <ng-container *ngIf="control?.invalid && control?.touched">
      <p *ngIf="control?.errors" class="ngx-validator">
        <span
          [innerHTML]="(control?.errors | getErrorMessage: customValidation | async) | interpolation: (control?.errors | getInterpolationData: customName : control)"></span>
      </p>
    </ng-container>
  `,
      styles: [`
      p {
        margin: 0;
        display: inline-block;
      }

      p:first-letter {
        text-transform: capitalize;
      }
    `]
    }]
  }], null, {
    control: [{
      type: Input
    }],
    customName: [{
      type: Input
    }],
    customValidation: [{
      type: Input
    }]
  });
})();
class NgxValidatorModule {}
NgxValidatorModule.ɵfac = function NgxValidatorModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxValidatorModule)();
};
NgxValidatorModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxValidatorModule
});
NgxValidatorModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxValidatorModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxValidatorComponent, GetErrorMessagePipe, InterpolationPipe, GetInterpolationDataPipe],
      imports: [CommonModule],
      exports: [NgxValidatorComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-validator
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxValidatorComponent, NgxValidatorModule, NgxValidatorService };
